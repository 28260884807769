import CustomButton from "../components/CustomButton";

const data = [
  {
    "Course Name": "Indoor Gardening...",
    Price: "$74.99",
    Student: 1,
    "Created At": "23/03/2024",
    View: (
      <CustomButton sx={{ backgroundColor: "blue.main" }} variant="contained">
        View
      </CustomButton>
    ),
  },
  {
    "Course Name": "Indoor Gardening...",
    Price: "$74.99",
    Student: 1,
    "Created At": "23/03/2024",
    View: (
      <CustomButton sx={{ backgroundColor: "blue.main" }} variant="contained">
        View
      </CustomButton>
    ),
  },
  {
    "Course Name": "Indoor Gardening...",
    Price: "$74.99",
    Student: 1,
    "Created At": "23/03/2024",
    View: (
      <CustomButton sx={{ backgroundColor: "blue.main" }} variant="contained">
        View
      </CustomButton>
    ),
  },
  {
    "Course Name": "Indoor Gardening...",
    Price: "$74.99",
    Student: 1,
    "Created At": "23/03/2024",
    View: (
      <CustomButton sx={{ backgroundColor: "blue.main" }} variant="contained">
        View
      </CustomButton>
    ),
  },
  {
    "Course Name": "Indoor Gardening...",
    Price: "$74.99",
    Student: 1,
    "Created At": "23/03/2024",
    View: (
      <CustomButton sx={{ backgroundColor: "blue.main" }} variant="contained">
        View
      </CustomButton>
    ),
  },
];

export default data;

export const purchased = [
    {
      date: "13-12-2022",
      customer: "Sok ChanDara",
      total: 20,
      status: true,
    },
    {
      date: "15-12-2022",
      customer: "Meas Malis",
      total: 35,
      status: true,
    },
    {
      date: "18-12-2022",
      customer: "Pich Samnang",
      total: 25,
      status: false,
    },
    {
      date: "20-12-2022",
      customer: "Hor Seyha",
      total: 45,
      status: true,
    },
    {
      date: "22-12-2022",
      customer: "Keo Sopheak",
      total: 30,
      status: true,
    },
    {
      date: "24-12-2022",
      customer: "Meas Sothea",
      total: 50,
      status: false,
    },
    {
      date: "27-12-2022",
      customer: "Pich Samnang",
      total: 40,
      status: true,
    },
    {
      date: "29-12-2022",
      customer: "Sok ChanDara",
      total: 55,
      status: true,
    },
    {
      date: "01-01-2023",
      customer: "Meas Malis",
      total: 60,
      status: false,
    },
    {
      date: "03-01-2023",
      customer: "Hor Seyha",
      total: 35,
      status: true,
    },
    {
      date: "05-01-2023",
      customer: "Keo Sopheak",
      total: 70,
      status: true,
    },
    {
      date: "07-01-2023",
      customer: "Meas Sothea",
      total: 45,
      status: false,
    },
    {
      date: "10-01-2023",
      customer: "Pich Samnang",
      total: 80,
      status: true,
    },
    {
      date: "12-01-2023",
      customer: "Sok ChanDara",
      total: 65,
      status: true,
    },
    {
      date: "15-01-2023",
      customer: "Meas Malis",
      total: 75,
      status: false,
    },
    {
      date: "17-01-2023",
      customer: "Hor Seyha",
      total: 90,
      status: true,
    },
    {
      date: "20-01-2023",
      customer: "Keo Sopheak",
      total: 85,
      status: true,
    },
    {
      date: "22-01-2023",
      customer: "Meas Sothea",
      total: 95,
      status: false,
    },
    {
      date: "25-01-2023",
      customer: "Pich Samnang",
      total: 100,
      status: true,
    },
    {
      date: "27-01-2023",
      customer: "Sok ChanDara",
      total: 110,
      status: true,
    },
  ];
export const fakeProductData = [
  {
    id: "1",
    title: "Tool",
    price: "100$",
    src: "https://picsum.photos/id/122/210/200",
  },
  {
    id: "2",
    title: "Gadget",
    price: "50$",
    src: "https://picsum.photos/id/123/210/200",
  },
  {
    id: "3",
    title: "Device",
    price: "150$",
    src: "https://picsum.photos/id/124/210/200",
  },
  {
    id: "4",
    title: "Accessory",
    price: "20$",
    src: "https://picsum.photos/id/125/210/200",
  },
  {
    id: "5",
    title: "Widget",
    price: "75$",
    src: "https://picsum.photos/id/126/210/200",
  },
  {
    id: "6",
    title: "Gizmo",
    price: "30$",
    src: "https://picsum.photos/id/127/210/200",
  },
  {
    id: "7",
    title: "Instrument",
    price: "200$",
    src: "https://picsum.photos/id/128/210/200",
  },
  {
    id: "8",
    title: "Machine",
    price: "500$",
    src: "https://picsum.photos/id/129/210/200",
  },
];

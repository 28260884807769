export const products = [
  {
    name: "Grow Lights - LED or fluorescent grow lights",
    category: "Planting Tools",
    price: 10,
    quantity: 5,
  },
  {
    name: "Garden Hose",
    category: "Planting Tools",
    price: 10,
    quantity: 5,
  },
  {
    name: "Soil pH Meter",
    category: "Planting Tools",
    price: 10,
    quantity: 5,
  },
  {
    name: "Lawn Mower",
    category: "Planting Tools",
    price: 10,
    quantity: 5,
  },
  {
    name: "Plant Fertilizer",
    category: "Planting Tools",
    price: 10,
    quantity: 5,
  },
  {
    name: "Soil pH Meter",
    category: "Planting Tools",
    price: 10,
    quantity: 5,
  },
  {
    name: "Lawn Mower",
    category: "Planting Tools",
    price: 10,
    quantity: 5,
  },
  {
    name: "Plant Fertilizer",
    category: "Planting Tools",
    price: 10,
    quantity: 5,
  },
  {
    name: "Soil pH Meter",
    category: "Planting Tools",
    price: 10,
    quantity: 5,
  },
  {
    name: "Lawn Mower",
    category: "Planting Tools",
    price: 10,
    quantity: 5,
  },
  {
    name: "Plant Fertilizer",
    category: "Planting Tools",
    price: 10,
    quantity: 5,
  },
];

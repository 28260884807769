import { Box, Typography } from "@mui/material";

function EditProductPage() {
  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="h2">Edit Product</Typography>
    </Box>
  );
}

export default EditProductPage;

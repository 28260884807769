export const cateData = [
  {
    category_id: 1,
    label: "Plant",
    description: "Something related to plant",
  },
  {
    category_id: 2,
    label: "Fertilizer",
    description: "Something related to fertilizer",
  },
  {
    category_id: 3,
    label: "Seed",
    description: "Something related to seed",
  },
  {
    category_id: 4,
    label: "Tool",
    description: "Something related to tool",
  },
];

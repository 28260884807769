import { Box, Typography } from "@mui/material";

function EditCoursePage() {
  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="h2">Edit Course</Typography>
    </Box>
  );
}

export default EditCoursePage;
